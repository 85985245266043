<template>
  <div
    class="card card--table"
  >
    <div
      v-b-toggle="`collapseSection-${id}`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">{{ title }}</a> <span class="badge bg-light-success">{{ (processedFiles ? processedFiles.length : 0) + (type == PUBLICATIONS && item.important_file && item.important_file.url ? 1 : 0) }}</span>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a
              data-action="collapse"
            ><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <b-collapse
        :id="`collapseSection-${id}`"
      >
        <div
          v-if="type == PUBLICATIONS && item.important_file"
          class="card-body"
        >
          <div class="row">
            <div class="col">
              <h4 class="mb-1">
                {{ labels['upload_publication_section_description'] }}
              </h4>
              <p class="text-warning">
                <i data-feather="alert-triangle" /> It is highly recommended to upload this document
              </p>
              <div class="mb-1">
                <div class="input-group">
                  <div
                    class="input-group custom-file-button"
                  >
                    <label
                      class="input-group-text"
                      :for="`formFile-important`"
                    >Select file</label>
                    <input
                      :id="`formFile-${type}`"
                      :ref="`file-important`"
                      class="form-control"
                      type="file"
                      @change="uploadImportantFile"
                    >
                    <a
                      id="linkToFile"
                      :href="item.important_file.url"
                      class="btn btn-outline-primary"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Go to file"
                      target="_blank"
                    ><i data-feather="external-link" /></a>
                  </div>
                </div>
              </div>
              <div
                v-if="item.important_file.url"
                class="mb-1"
              >
                <label
                  for=""
                  class="form-label page-break"
                >{{ labels['document_uploaded'] }}</label>
                <div>
                  <a
                    id="linkToFile"
                    :href="item.important_file.url"
                    class="text-success"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Go to file"
                    target="_blank"
                  >{{ item.important_file.name }}</a>
                </div>
              </div>
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['document_description'] }}</label>
                <input
                  v-model="item.important_file.description"
                  type="text"
                  class="form-control"
                >
              </div>
              <div class="mb-1">
                <div class="form-check">
                  <input
                    id="inlineCheckbox1"
                    v-model="item.important_file.public"
                    class="form-check-input"
                    type="checkbox"
                    value="checked"
                  >
                  <label
                    class="form-check-label"
                    for="inlineCheckbox1"
                  >{{ labels['make_pdf_public'] }}</label>
                </div>
              </div>
            </div>
          </div>
          <hr>
        </div>
        <div
          v-if="type == INTEREST_FORM"
          class="card-body"
        >
          <div class="mt-2 mb-2">
            <label
              for=""
              class="form-label"
            >{{ labels['description_interest_form'] }}</label>
            <TextArea
              :name="'description_interest_form'"
              :answer="{value: description}"
              :edit="true"
              @submitAnswer="submitAnswer($event)"
            />
          </div>
        </div>
        <div
          class="
          card-header
          d-flex
          align-items-center
          justify-content-between
        "
        >
          <div>
            <h4><strong>{{ processedFiles ? processedFiles.length : 0 }}</strong> files</h4>
          </div>
        </div>
        <div class="table-responsive-sm">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>Title</th>
                <th v-if="showDescription">
                  Description
                </th>
                <th>Format</th>
                <th v-if="type == COMPATIBILITY">
                  Compatibility document
                </th>
                <th v-if="type == COMPATIBILITY || type == DOCUMENTATION">
                  Main file
                </th>
                <th v-if="type == AGREEMENT">
                  Signature date
                </th>
                <th v-if="type == AGREEMENT">
                  Current
                </th>
                <th>Weight</th>
                <th v-if="type == SUPPORT || type == PUBLICATIONS || type == AGREEMENT">
                  To
                </th>
                <th v-if="type == DOCUMENTATION">
                  Visibility
                </th>
                <th>Created</th>
                <th class="text-end">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="file, index in processedFiles">
                <tr
                  v-if="file"
                  :key="file.id"
                  :style="{'--bs-table-accent-bg': (file.to && file.to.includes('Only admin') ? 'lightgoldenrodyellow' : '')}"
                >
                  <td>{{ file.name }}</td>
                  <td v-if="showDescription">
                    <small>{{ file.description }}</small>
                  </td>
                  <td>{{ file.extension }}</td>
                  <td v-if="type == COMPATIBILITY">
                    <span
                      v-if="file.compatibility_document == true"
                      class="badge bg-light-success"
                    >Yes</span>
                    <span
                      v-if="file.compatibility_document == false"
                      class="badge bg-light-danger"
                    >No</span>
                  </td>
                  <td v-if="type == COMPATIBILITY || type == DOCUMENTATION">
                    <span
                      v-if="file.main_file == true"
                      class="badge bg-light-success"
                    >Yes</span>
                    <span
                      v-if="file.main_file == false"
                      class="badge bg-light-danger"
                    >No</span>
                  </td>
                  <td v-if="type == AGREEMENT">
                    {{ file.signature_date }}
                  </td>
                  <td v-if="type == AGREEMENT">
                    <span
                      v-if="file.current == true"
                      class="badge bg-light-success"
                    >Yes</span>
                    <span
                      v-if="file.current == false"
                      class="badge bg-light-danger"
                    >No</span>
                  </td>
                  <td>{{ file.weight }}MB</td>
                  <td v-if="type == SUPPORT || type == DOCUMENTATION || type == PUBLICATIONS || type == AGREEMENT">
                    <template v-if="Array.isArray(file.to)">
                      <span
                        v-for="file_type, index in file.to"
                        :key="file_type"
                        href=""
                        class="badge badge-light-secondary"
                        :style="index != 0 ? 'margin-left: 5px;' : ''"
                      >{{ file_type }} {{ file_type == 'Specific user' ? (file.user ? ` -> ${file.user.full_name}` : '') : '' }}</span>
                    </template>
                    <span
                      v-else
                      class="badge badge-light-secondary"
                    >
                      {{ file.to }}
                    </span>
                  </td>
                  <td>{{ file.created_at }}</td>
                  <td class="text-end">
                    <a
                      :href="file.url"
                      target="_blank"
                      class="
                    btn btn-icon btn-icon
                    rounded-circle
                    btn-flat-dark
                    me-1
                  "
                    ><i data-feather="eye" /></a>
                    <a
                      v-if="(type == SUPPORT || type == AGREEMENT || type == DOCUMENTATION || type == DEFAULT) && file.id"
                      class="
                      btn btn-icon btn-icon
                      rounded-circle
                      btn-flat-dark
                      me-1
                    "
                      @click="editFile(index)"
                    ><i data-feather="edit" /></a>
                    <a
                      v-if="canEdit"
                      class="
                          btn btn-icon btn-icon
                          rounded-circle
                          btn-flat-danger
                        "
                      data-bs-toggle="modal"
                      data-bs-target="#modalDeleteWithInput"
                      @click="deleteFile(file.id, index)"
                    ><i data-feather="trash-2" /></a>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>

        <div class="card-body">
          <a
            v-if="canEdit"
            class="btn btn-sm btn-dark dis-ib"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            @click="openEdit = !openEdit"
          ><i data-feather="plus" /> Add file</a>
          <b-collapse
            :visible="openEdit"
            class="collapse mt-3"
          >
            <div
              class="card shadow-none bg-transparent border-secondary"
            >
              <div class="card-body">
                <h3 v-if="type == AGREEMENT">
                  {{ editItem === null ? 'Add' : 'Edit' }} file <br>
                  <div class="mt-1">
                    <div class="form-check form-check-success form-switch d-flex align-items-center">
                      <input
                        id="publishContent"
                        v-model="current"
                        type="checkbox"
                        class="form-check-input"
                      >
                      <label
                        class="form-check-label"
                        for="publishContent"
                      ><small class="ms-1">{{ labels['add_file.current'] }}</small></label>
                    </div>
                  </div>
                </h3>
                <h3 v-else>
                  {{ editItem === null ? 'Add' : 'Edit' }} file
                </h3>
                <div class="row">
                  <div class="mb-1 col-12">
                    <div
                      class="input-group custom-file-button"
                    >
                      <label
                        class="input-group-text"
                        :for="`formFile-${type}`"
                      >Select file</label>
                      <input
                        :id="`formFile-${type}`"
                        :ref="`file-${type}`"
                        class="form-control"
                        type="file"
                      >
                    </div>
                  </div>
                </div>
                <div
                  v-if="type == AGREEMENT"
                  class="row"
                >
                  <div class="col-8">
                    <div class="row">
                      <div class="col-sm-9">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['add_file.name'] }}</label>
                          <input
                            v-model="name"
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div
                          class="mb-1"
                        >
                          <label
                            :for="`formFile-${type}`"
                            class="form-label"
                          >{{ labels['add_file.date_of_signature'] }}</label>
                          <date-picker
                            v-model="signature_date"
                            class="w-100"
                            format="YYYY-MM-DD"
                            value-type="format"
                          />
                        </div>
                      </div>
                      <div
                        class="col-sm-12"
                      >
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['add_file.description'] }}</label>
                          <textarea
                            id=""
                            v-model="file_description"
                            name=""
                            rows="3"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <h6 class="mt-1">
                      To:
                    </h6>
                    <hr>
                    <div class="form-check-success form-switch mb-2">
                      <input
                        id="fileToApplicants"
                        v-model="to"
                        :value="'All'"
                        type="checkbox"
                        class="form-check-input"
                      >
                      <label
                        class="form-check-label ms-1"
                        for="fileToApplicants"
                      >
                        {{ labels['add_file.all'] }}</label>
                    </div>
                    <div class="form-check-success form-switch mb-2">
                      <input
                        id="fileToReferees"
                        v-model="to"
                        :value="'Specific user'"
                        type="checkbox"
                        class="form-check-input"
                        @click="specificUser = !specificUser"
                      >
                      <label
                        class="form-check-label ms-1"
                        for="fileToReferees"
                      >
                        {{ labels['add_file.specific_user'] }}</label>
                      <v-select
                        v-if="specificUser"
                        v-model="selectedUser"
                        label="name"
                        :options="users"
                        :get-option-key="option => option.id"
                        @search="onSearch({ name: $event, host: institution }, 'users/fetchFakeUsers')"
                      />
                    </div>
                    <div class="form-check-success form-switch mb-2">
                      <input
                        id="fileToHosts"
                        v-model="to"
                        :value="'Admin'"
                        type="checkbox"
                        class="form-check-input"
                      >
                      <label
                        class="form-check-label ms-1"
                        for="fileToHosts"
                      >
                        {{ labels['add_file.admin'] }}</label>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="row"
                >
                  <div :class="type == SUPPORT || type == DOCUMENTATION || type == PUBLICATIONS || type == COMPATIBILITY ? 'col-sm-6' : 'col-sm-12'">
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >{{ labels['add_file.name'] }}</label>
                      <input
                        v-model="name"
                        type="text"
                        class="form-control"
                      >
                    </div>
                    <div
                      v-if="showDescription"
                      class="mb-1"
                    >
                      <label
                        for=""
                        class="form-label"
                      >{{ labels['add_file.description'] }}</label>
                      <textarea
                        id=""
                        v-model="file_description"
                        name=""
                        rows="3"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div
                    v-if="type == SUPPORT"
                    class="col-sm-6"
                  >
                    <h6 class="mt-1">
                      To:
                    </h6>
                    <hr>
                    <div class="form-check-success form-switch mb-2">
                      <input
                        id="fileToApplicants"
                        v-model="to"
                        :value="'Applicants'"
                        type="checkbox"
                        class="form-check-input"
                      >
                      <label
                        class="form-check-label ms-1"
                        for="fileToApplicants"
                      >
                        {{ labels['add_file.applicants'] }}</label>
                    </div>
                    <div class="form-check-success form-switch mb-2">
                      <input
                        id="fileToReferees"
                        v-model="to"
                        :value="'Referees'"
                        type="checkbox"
                        class="form-check-input"
                      >
                      <label
                        class="form-check-label ms-1"
                        for="fileToReferees"
                      >
                        {{ labels['add_file.referees'] }}</label>
                    </div>
                    <div class="form-check-success form-switch mb-2">
                      <input
                        id="fileToHosts"
                        v-model="to"
                        :value="'Hosts'"
                        type="checkbox"
                        class="form-check-input"
                      >
                      <label
                        class="form-check-label ms-1"
                        for="fileToHosts"
                      >
                        {{ labels['add_file.hosts'] }}</label>
                    </div>
                    <div class="form-check-success form-switch mb-2">
                      <input
                        id="fileToEvaluators"
                        v-model="to"
                        :value="'Evaluators'"
                        type="checkbox"
                        class="form-check-input"
                      >
                      <label
                        class="form-check-label ms-1"
                        for="fileToEvaluators"
                      >
                        {{ labels['add_file.evaluators'] }}</label>
                    </div>
                  </div>
                  <div
                    v-if="(type == DOCUMENTATION || type == PUBLICATIONS || type == COMPATIBILITY) && (loggedUser.roles.includes('super-admin') || loggedUser.roles.includes('publication-admin'))"
                    class="col-sm-6"
                  >
                    <h6 class="mt-1">
                      Visibility:
                    </h6>
                    <hr>
                    <div class="form-check-success form-switch mb-2">
                      <input
                        id="fileToApplicants"
                        v-model="to"
                        :value="'All'"
                        type="radio"
                        name="radio"
                        class="form-check-input"
                      >
                      <label
                        class="form-check-label ms-1"
                        for="fileToApplicants"
                      >
                        {{ labels['add_file.all'] }}</label>
                    </div>
                    <div class="form-check-success form-switch mb-2">
                      <input
                        id="fileToReferees"
                        v-model="to"
                        :value="'Only admin'"
                        type="radio"
                        name="radio"
                        class="form-check-input"
                      >
                      <label
                        class="form-check-label ms-1"
                        for="fileToReferees"
                      >
                        {{ labels['add_file.admin'] }}</label>
                    </div>
                    <hr>
                    <div
                      v-if="type != DOCUMENTATION"
                      class="form-check-success form-switch mb-2"
                    >
                      <input
                        id="fileToReferees"
                        v-model="compatibility_document"
                        :value="true"
                        type="checkbox"
                        class="form-check-input"
                      >
                      <label
                        class="form-check-label ms-1"
                        for="fileToReferees"
                      >
                        {{ labels['add_file.compatibility_contract'] }}</label>
                    </div>
                    <template
                      v-if="(type == COMPATIBILITY || type == DOCUMENTATION) && (loggedUser.roles.includes('super-admin') || loggedUser.roles.includes('publication-admin'))"
                    >
                      <hr>
                      <div
                        class="form-check-success form-switch mb-2"
                      >
                        <input
                          id="mainFile"
                          v-model="main_file"
                          :value="true"
                          type="checkbox"
                          class="form-check-input"
                        >
                        <label
                          class="form-check-label ms-1"
                          for="mainFile"
                        >
                          {{ labels['add_file.main_file'] }}</label>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="mb-2">
                  <a
                    class="btn btn-sm btn-outline-success me-1"
                    @click="uploadFile"
                  >Upload file</a>
                  <a
                    class="btn btn-sm btn-flat-danger"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    @click="cancelEdit()"
                  >Cancel</a>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import vSelect from 'vue-select'
import { BCollapse } from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import TextArea from '../../../../convos/partials/input-types/TextArea.vue'
import 'vue2-datepicker/index.css'

export default {
  components: {
    TextArea,
    BCollapse,
    DatePicker,
    vSelect,
  },
  props: {
    title: { type: String, required: true },
    type: { type: Number, required: true },
    id: { type: Number, required: true },
    visible: { type: Boolean, required: false, default: true },
    files: { type: Array, required: false, default: null },
    description: { type: String, required: false, default: '' },
    item: { type: Object, required: false, default: () => {} },
    canEdit: { type: Boolean, required: false, default: true },
    showDescription: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      // File types
      INTEREST_FORM: 1,
      SUPPORT: 2,
      DEFAULT: 3,
      DOCUMENTATION: 4,
      PUBLICATIONS: 5,
      AGREEMENT: 6,
      COMPATIBILITY: 7,
      // END: File types
      processedFiles: [],
      newFiles: [],
      name: '',
      file_description: '',
      to: [],
      signature_date: null,
      current: false,
      openEdit: false,
      editItem: null,
      selectedUser: false,
      specificUser: false,
      compatibility_document: false,
      main_file: false,
      labels: [],
    }
  },
  computed: {
    ...mapGetters({
      users: 'users/fakeUsers',
      loggedUser: 'auth/admin',
      institution: 'institutions/institution',
    }),
  },
  watch: {
    files() {
      this.processedFiles = this.files
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
    },
  },
  async mounted() {
    if (this.files) {
      this.processedFiles = this.files.filter(e => e) ?? []
    } else {
      this.processedFiles = []
    }
    try {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/components.manage_files`)
      this.labels = data.data
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    uploadImportantFile() {
      const file = this.$refs['file-important'].files[0]
      this.item.important_file.data = {
        name: file.name,
        original_name: file.name,
        description: this.file_description,
        weight: (file.size / 1024 / 1024).toFixed(2),
        extension: file.type,
        created_at: 'Pending to save...',
        to: this.to,
        file,
        file_type: this.type,
        url: URL.createObjectURL(file),
      }

      this.item.important_file.url = URL.createObjectURL(file)
    },
    uploadFile() {
      const file = this.$refs[`file-${this.type}`].files[0]

      if (this.editItem === null) {
        this.processedFiles.push({
          signature_date: this.signature_date,
          current: this.current,
          name: this.name,
          original_name: file.name,
          description: this.file_description,
          weight: (file.size / 1024 / 1024).toFixed(2),
          extension: file.type,
          created_at: 'Pending to save...',
          to: this.to,
          user_id: this.selectedUser ? this.selectedUser.id : null,
          file,
          file_type: this.type,
          url: URL.createObjectURL(file),
          compatibility_document: this.compatibility_document,
          main_file: this.main_file,
        })

        this.newFiles.push({
          signature_date: this.signature_date,
          current: this.current,
          name: this.name,
          original_name: file.name,
          description: this.file_description,
          weight: (file.size / 1024 / 1024).toFixed(2),
          extension: file.type,
          created_at: 'Pending to save...',
          to: this.to,
          user_id: this.selectedUser ? this.selectedUser.id : null,
          file,
          file_type: this.type,
          compatibility_document: this.compatibility_document,
          main_file: this.main_file,
        })
      } else {
        this.processedFiles[this.editItem] = {
          id: this.processedFiles[this.editItem].id,
          signature_date: this.signature_date,
          current: this.current,
          edited: !!file,
          name: this.name,
          original_name: file ? file.name : this.processedFiles[this.editItem].original_name,
          description: this.file_description,
          weight: file ? (file.size / 1024 / 1024).toFixed(2) : this.processedFiles[this.editItem].weight,
          extension: file ? file.type : this.processedFiles[this.editItem].extension,
          created_at: this.processedFiles[this.editItem].created_at,
          to: this.to,
          file: file || this.processedFiles[this.editItem].file,
          file_type: this.type,
          user_id: this.selectedUser ? this.selectedUser.id : null,
          user: this.selectedUser,
          url: file ? URL.createObjectURL(file) : this.processedFiles[this.editItem].url,
          compatibility_document: this.compatibility_document,
          main_file: this.main_file,
        }
      }
      this.name = ''
      this.file_description = ''
      this.signature_date = null
      this.current = false
      this.to = []

      this.selectedUser = false
      this.specificUser = false
      this.openEdit = false
      this.editItem = null
      this.saveFiles()
    },
    editFile(index) {
      this.openEdit = true
      const file = this.files[index]

      this.editItem = index

      this.name = file.name
      this.file_description = file.description
      this.signature_date = file.signature_date
      this.current = file.current
      if (typeof file.to === 'string' || file.to instanceof String) {
        this.to = [file.to]
      } else {
        this.to = file.to
      }
      this.selectedUser = file.user
      this.specificUser = file.to ? file.to.includes('Specific user') : false
    },
    cancelEdit() {
      this.openEdit = false
      this.name = null
      this.file_description = null
      this.signature_date = null
      this.current = false
      this.selectedUser = false
      this.specificUser = false

      this.editItem = null
    },
    saveFiles() {
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      this.$emit('saveAnswer', { id: `files-${this.type}`, data: this.newFiles })
    },
    deleteFile(fileId, index) {
      if (fileId) {
        this.$store.dispatch('lines/deleteFile', fileId)
      }

      this.processedFiles.splice(index, 1)
      this.newFiles.splice(index, 1)
      this.cancelEdit()
      Vue.swal('File deleted successfully!', '', 'success')
    },
    submitAnswer(data) {
      this.$emit('saveAnswer', { id: 'description_interest_form', data })
    },
    async onSearch(search) {
      if (search !== '') {
        await this.$store.dispatch('users/fetchFakeUsers', search)
      }
    },
  },
}
</script>
